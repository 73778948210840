export default [
    {
        path:'/Internet_things/settings',
        name:'InternetThings',
        component:()=>import('../views/Internet_things/settings'),
        meta:{
            title:'物联网-设置',
            keepAlive:false
        }
    }
]
