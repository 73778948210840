import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ViewUI from 'view-design'
import Viewer from "v-viewer"
import './comfun'
import 'view-design/dist/styles/iview.css'
import store from './store'

Vue.config.productionTip = false

Vue.use(ViewUI)
Vue.use(Viewer)
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})
new Vue({
  el:'#app',
  router,
  store,
  render: h => h(App)
})
