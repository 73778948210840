import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "./home"
import Login from './login'
import Sms from './sms'
import Ai from './ai'
import Device from './device'
import Monitoring from './monitoring'
import Internet from './Internet'
import Iot from './iot'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    ...Home,
    ...Login,
    ...Sms,
    ...Ai,
	...Device,
	...Monitoring,
	...Internet,
	...Iot
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
