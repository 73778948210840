export default [{
		path: '/monitoring/list',
		name: 'MonitoringList',
		component: () => import('../views/monitoring/list'),
		meta: {
			title: '监控列表',
			keepAlive: false
		}
	},
	{
		path: '/monitoring/info',
		name: 'MonitoringInfo',
		component: () => import('../views/monitoring/info'),
		meta: {
			title: '监控列表详情',
			keepAlive: false
		}
	}
]
