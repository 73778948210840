import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userInfo: {
			uid: null, //云端账号ID
			uname: '', //云端账号昵称
			store_id: null //商户ID
		},
		showHead:1
	},
	mutations: {
		UPDATE_USER(state, res) {
			state.userInfo = res
		},
		UPDATE_SHOW(state, res) {
			state.showHead = res
		}
	},
	actions: {
		setAdmin({commit}, res) {
			commit('UPDATE_USER', res)
		},
		setShow({commit}, res) {
			commit('UPDATE_SHOW', res)
		},
	},
	modules: {}
})