<template>
	<div>
		<audio ref="audio1" :src="audioSrc" v-show="false" :muted="true"></audio>
		<Modal v-model="modal.show" title="报警弹窗" width="80" :footer-hide="true" @on-cancel="cancelAlarm">
			<div class="receive-alarm-modal-body">
				<div class="receive-alarm-modal-body-left">
					<div class="receive-alarm-modal-body-left-item"
						:class="{'receive-alarm-modal-body-left-item-checked': index == currentIndex}"
						v-for="(item,index) in alarm_list" :key="index" @click="deviceAlarmChange(index)">
						<Tooltip placement="left" max-width="500">
							<div>用户名：{{ item.u_name || '未绑定用户' }}</div>
							<div>设备号：{{ item.device_id }}</div>
							<template slot="content">
								<div>用户名：{{ item.u_name || '未绑定用户' }}</div>
								<div>设备号：{{ item.device_id }}</div>
							</template>
						</Tooltip>
					</div>
				</div>
				<div class="receive-alarm-modal-body-right">
					<div id="receiveAlarmMap"></div>
					<div class="user-info" v-if="data.is_binding">
						<div>用户名：{{ data.u_name }}</div>
						<div>用户手机号：{{ data.u_phone }}</div>
						<div>用户姓名：{{ data.d_name }}</div>
						<div>报警类型：{{ data.type == 2 ? 'SOS报警' : '电子围栏报警' }}</div>
						<div>报警时间：{{ data.create_date }}</div>
						<div>报警地址：{{ data.addr }}</div>
					</div>
					<div v-else class="no-binding">没有绑定设备</div>
				</div>
			</div>

		</Modal>
	</div>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader';
	import {
		shallowRef
	} from '@vue/reactivity'
	export default {
		name: 'ReceiveAlarm',
		data() {
			return {
				//地图实例创建者
				AMap: null,
				alarm_list: [], // sos报警弹窗展开时，收到新的报警则列表新增一个
				currentIndex: 0, // 默认选择的设备报警信息
				data: {},
				audioSrc: require('@/assets/mp3/baojing.mp3'),
				create_date: '',
				timer: null,
				modal: {
					show: false,
				},
				circle: null
			}
		},
		setup() {
			//地图实例
			const map = shallowRef(null);
			return {
				map
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let _this = this;
                var setShow = localStorage.getItem('setShow')
                this.$store.dispatch('setShow', setShow)
				if (localStorage.getItem('store_token') && setShow == 1) {
					this.requestApi('/adm/get_device_alarm_info', { create_date: this.create_date }).then((res) => {
						if (res.status == 1) {
							if (JSON.stringify(res.data) != '[]') {
								console.log(res.date);
								// 不是第一次获取的报警
								if (_this.create_date) {
									_this.$refs.audio1.muted = false;
									_this.$refs.audio1.currentTime = 0; // 从头开始
									_this.$refs.audio1.play().catch((result) => {
										console.log(result)
									}); // 播放提示音
									for(let index in res.data){
										if (res.data[index] !== null && (res.data[index].type == 2 || res.data[index].type == 23)) {
											if (_this.alarm_list.length == 0) {
												_this.data = res.data[index];
												_this.modal.show = true;
												_this.MapInit();
											}
											_this.alarm_list.push(res.data[index]);
										}
									}
								}
								// 赋值最新的报警时间
								_this.create_date = res.date;
							}
							// 是否开启定时器
							if (!_this.timer) {
								_this.timer = setInterval(() => {
									_this.init();
								}, 4000);
							}
						}
					});
				} else {
					this.close();
				}
			},
			// 关闭 定时器
			close() {
				console.log('close timer')
				clearInterval(this.timer);
				this.timer = null; // 赋值为null以免定时器无法正常开启
			},
			// 初始化地图
			MapInit() {
				var _this = this;
				AMapLoader.load({
					key: 'f0a74a4ce476bbf1ff603389e5ff3105',
					version: "2.0",
					plugins: ['AMap.Scale','AMap.CircleEditor', 'AMap.AutoComplete'], // 插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					_this.AMap = AMap
					_this.map = new AMap.Map("receiveAlarmMap", { //设置地图容器id
						viewMode: "2D", //是否为3D地图模式
						zoom: 5 //初始化地图级别
					});
					// 报警类型是 电子围栏报警
					if(_this.data.type == 23){
						// 创建圆形实例
						_this.circle = new AMap.Circle({
							center: new AMap.LngLat(_this.data.enc_longitude, _this.data.enc_latitude), // 圆心位置
							radius: _this.data.enc_radius, // 圆半径
							fillColor: '#00B2D5', // 圆形填充颜色
							strokeColor: '#fff', // 描边颜色
							strokeWeight: 2, // 描边宽度
							draggable: false
						});
						_this.map.add(_this.circle);
					}
					// 设备上传的位置
					if (_this.data.latitude && _this.data.longitude) {
						let content = "<div class='custom-content-marker'>" +
							"<img src='https://www.yiyangiot.com/assets/ic_marker_bg_white.632936f0.png' />" +
							"<img src='//xunliao-1-1257941115.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/1659924688423.png' class='green' />" +
							"<img class='avatar' src='//xunliao-1-1257941115.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/1659923948257.png' />" +
							"</div>";
						var marker = new AMap.Marker({
							position: new AMap.LngLat(_this.data.longitude, _this.data.latitude),
							offset: new AMap.Pixel(-23, -55),
							content: content,
							title: '北京',
							zoom: 13
						})
						_this.map.add([marker]);
					}
					_this.map.setFitView()
				})
			},
			// 关闭弹窗
			cancelAlarm() {
				this.modal.show = false;
				this.map.destroy();
				this.circle = null;
				this.map = null;
				this.alarm_list = [];
			},
			// 改变设备报警信息
			deviceAlarmChange(index) {
				this.currentIndex = index;
				this.map.destroy();
				this.circle = null;
				this.map = null;
				this.data = this.alarm_list[index];
				this.MapInit();
			}
		}
	}
</script>

<style scoped>
	.receive-alarm-modal-body {
		width: 100%;
		height: auto;
		display: flex;
	}

	.receive-alarm-modal-body-left {
		width: calc(20% - 12px);
		min-width: calc(20% - 12px);
		margin-right: 12px;
		border-top: 1px solid #dddddd;
		overflow-y: auto;
		max-height: 540px;
	}

	.receive-alarm-modal-body-left-item {
		width: 100%;
		height: 60px;
		line-height: 30px;
		padding: 0 16px;
		border-bottom: 1px solid #dddddd;
		overflow: hidden;
	}

	.receive-alarm-modal-body-left-item div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.receive-alarm-modal-body-left-item-checked {
		color: #2d8cf0;
	}

	.receive-alarm-modal-body-right {
		width: 80%;
		min-width: 80%;
	}

	#receiveAlarmMap {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 420px;
	}

	.user-info {
		margin-top: 12px;
		line-height: 36px;
	}

	.no-binding {
		height: 100px;
		line-height: 100px;
		font-size: 20px;
		color: #666;
		text-align: center;
	}

	/deep/ .custom-content-marker {
		position: relative;
	}

	/deep/ .custom-content-marker img {
		width: 45px;
		position: absolute;
		left: 0;
		top: 0;
	}

	/deep/ .custom-content-marker .avatar {
		width: 40px;
		height: 40px;
		left: 2.5px;
		top: 2.5px;
		border-radius: 50%;
	}

	/deep/ .custom-content-marker:hover .green {
		display: block;
	}

	/deep/ .custom-content-marker .green {
		display: none;
	}
</style>
