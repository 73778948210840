export default [{
		path: '/device/xueyaji',
		name: 'Xueyaji',
		component: () => import('@/views/device/xueyaji'),
		meta: {
			title: '血压计管理',
			keepAlive: false
		}
	},
	{
		path: '/device/xuetangyi',
		name: 'Xuetangyi',
		component: () => import('@/views/device/xuetangyi'),
		meta: {
			title: '血糖仪管理',
			keepAlive: false
		}
	},
	{
		path: '/device/list',
		name: 'DeviceList',
		component: () => import('@/views/device/list'),
		meta: {
			title: '设备列表',
			keepAlive: false
		}
	},
	{
		path: '/device/dtl',
		name: 'DeviceDtl',
		component: () => import('@/views/device/device_dtl'),
		meta: {
			title: '设备详情',
			keepAlive: false
		}
	},
	{
		path: '/device/map',
		name: 'DeviceMap',
		component: () => import('@/views/device/device_map'),
		meta: {
			title: '设备地图',
			keepAlive: false
		}
	},
	{
		path: '/warn/list',
		name: 'WarnList',
		component: () => import('@/views/device/warn_list'),
		meta: {
			title: '报警记录',
			keepAlive: false
		}
	},
	{
		path: '/warn/settings',
		name: 'WarnSettings',
		component: () => import('@/views/device/warn_settings'),
		meta: {
			title: '告警方案',
			keepAlive: false
		}
	},
	{
		path: '/device/package',
		name: 'DevicePackage',
		component: () => import('@/views/device/package'),
		meta: {
			title: '流量套餐',
			keepAlive: false
		}
	},
	{
		path: '/device/forward',
		name: 'DeviceForward',
		component: () => import('@/views/device/forward'),
		meta: {
			title: '转发配置',
			keepAlive: false
		}
	},
	{
		path: '/device/forward_log',
		name: 'DeviceForwardLog',
		component: () => import('@/views/device/forward_log'),
		meta: {
			title: '转发记录',
			keepAlive: false
		}
	}
]
