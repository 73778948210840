<template>
  <div id="menu" :class="{'small':menuMode=='small'}">
    <div class="menu-item" :class="{'active':isSelect}">
      <a class="menu-a" href="javascript:;" @click="changeIndex">
        <i class="menu-icon iconfont icon-baobiaokongzhitai"></i>
        <span v-if="menuMode=='default'">总览</span>
      </a>
    </div>
    <div class="menu-item" v-for="(item,index) in menuItem" :key="index" :class="{'active':isActive==index,'select':isActive==index && item.children.length}">
      <a href="javascript:;" class="menu-a" @click="selectMenu(index)">
        <i class="menu-icon iconfont" :class="item.icon"></i>
        <span v-if="menuMode=='default'">{{item.name}}</span>
        <i v-if="menuMode=='default'" class="menu-down" :class="{'iconfont icon-xiala1':item.children.length}"></i>
      </a>
      <div class="children" v-if="menuMode=='default'">
        <div class="c-item" v-for="(items,keys) in item.children" :key="keys">
          <a class="c-item-a" :class="{'sel-a':isChildren==keys}" href="javascript:;" @click="openUrl(items,keys)">{{items.name}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Header",
        props:{
            menuMode:{
                type:String,
                default:'default'
            }
        },
        data(){
            return{
                menuItem: [],
                isActive: -1,
                isSelect:-1,
                isChildren:-1,
            }
        },
        created() {
            this.init()
        },
        methods:{
            //获取栏目
            init(){
                var _this = this;
                var setShow = localStorage.getItem('setShow')
                this.$store.dispatch('setShow', setShow)
                if (setShow == 0) return false;
                this.requestApi('/adm/get_menu').then(function (res) {
                    if (res.status){
                        _this.menuItem = res.data;
                    }
                })
            },
            openUrl(item,index){
                this.isChildren = index;
                if(!item.url) return false;
                else this.toUrl(item.url);
            },
            selectMenu(key) {
                this.isSelect = false;
                if (this.isActive == key) this.isActive = -1;
                else this.isActive = key;
                if (!this.menuItem[key].children.length) this.toUrl(this.menuItem[key].url);
            },
            changeIndex(){
                this.isActive = -1;
                this.isChildren = -1;
                this.isSelect = true;
                this.toUrl('/');
            },
        }
    }
</script>

<style scoped>

</style>
