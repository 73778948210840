export default [
    {
        path:'/login',
        name:'Login',
        component:()=>import('../views/login/login'),
        meta:{
            title:'登录页',
            keepAlive:false
        }
    },
    {
        path:'/authorization',
        name:'Authorization',
        component:()=>import('../views/login/authorization'),
        meta:{
            title:'授权页',
            keepAlive:false
        }
    },
	{
		path:'/private_renew',
		name:'PrivateRenew',
		component:()=>import('../views/login/private_renew'),
		meta:{
			title:'私有部署续费',
			keepAlive:false
		}
	}
]
