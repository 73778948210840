<template>
    <div id="app">
        <Header v-if="$store.state.showHead==1" :menuMode="menuMode"></Header>
        <div id="nav" v-if="$store.state.showHead==1" :class="{'nav-small':menuMode=='small'}">
            <i class="iconfont icon-indent-remove-full" @click="changeMenu" :title="menuMode=='default'?'收缩侧边栏':'展开侧边栏'"
               style="margin-right: 22px;"></i>
            <i class="iconfont icon-refresh-line" @click="clickReload" title="刷新"></i>
            <Dropdown transfer trigger="click" style="float: right;margin-left: 20px" @on-click="changeDown">
                <a href="javascript:void(0)">
                    {{ $store.state.userInfo.uname ? $store.state.userInfo.uname : '' }}
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem name="edit">修改</DropdownItem>
                    <DropdownItem name="out_login">退出登录</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
        <div :class="{'main-view':$store.state.showHead==1,'main-small':menuMode=='small'}">
            <keep-alive>
                <router-view ref="reload" v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view ref="reload" v-if="!$route.meta.keepAlive"></router-view>
        </div>
        <div v-if="$store.state.showHead==1">
            <receive-alarm ref="receive_alarm"></receive-alarm>
        </div>
        <Modal v-model="modal" title="修改密码" :footer-hide="true">
            <Form>
                <div style="display: flex; align-items: baseline;">
                    云端账号：<Input style="width: 354px; margin-bottom: 20px;" :disabled="true" v-model="$store.state.userInfo.uname"></Input>
                </div>
                <div style="display: flex; align-items: baseline;">
                    云端密码：<Input style="width: 354px; margin-bottom: 20px;" placeholder="输入新密码" v-model="cipher"></Input>
                </div>
                <div style="text-align: right;">
                    <Button @click="revise_cipher">确认修改</Button>
                </div>
            </Form>
        </Modal>
    </div>
</template>
<script>
import Header from '@/components/common/header'
import ReceiveAlarm from '@/components/common/receive_alarm'
import md5 from 'js-md5'

export default {
    name: "APP",
    components: {
        Header,
        ReceiveAlarm
    },
    data() {
        return {
            menuMode: 'default',
            modal: false,
            cipher: null,
        }
    },
    watch: {
        '$route.path': function (path) {
            let domainWhitelist;
            domainWhitelist = [
                '/login',
                '/authorization',
				'/private_renew'
            ];
            var type = 1
            if (domainWhitelist.indexOf(path) != -1) {
                type = 0;
            }
            this.$store.dispatch('setShow', type)
            localStorage.setItem('setShow',type)
        },
    },
    created() {
        //将登入后的缓存数据拿出来重新赋值VUEX
        let userInfo = localStorage.getItem('userInfo')
        if (userInfo) this.$store.dispatch('setAdmin', JSON.parse(userInfo))
    },
    methods: {
        changeDown(name) {
            var _this = this;
            if (name == 'out_login') {
                _this.$Modal.confirm({
                    title: '提示',
                    content: '确认退出登录吗？',
                    onOk: () => {
                        _this.requestApi('/adm/login_out').then(function (res) {
                            if (res.status) {
                                localStorage.removeItem('store_token');
                                localStorage.removeItem('userinfo');
                                localStorage.removeItem('store_out_time');
                                localStorage.setItem('setShow',0)
                                _this.$store.dispatch('setShow', 0)
                                _this.$store.dispatch('setAdmin', {
                                    uid: null, //云端账号ID
                                    uname: '', //云端账号昵称
                                    store_id: null //商户ID
                                })
                                setTimeout(function () {
                                    _this.toUrl('/login');
                                }, 500)
                                _this.alertSucc(res.msg)
                            } else {
                                _this.alertErr(res.msg);
                            }
                        })
                    },
                });
            } else if (name == 'edit') {
                _this.cipher = null
                _this.modal = true
            }
        },
        //收缩、展开菜单栏
        changeMenu() {
            this.menuMode = this.menuMode == 'default' ? 'small' : 'default';
        },
        //点击刷新
        clickReload() {
            if (this.$refs.reload.clickReload) this.$refs.reload.clickReload();
            else if (this.$refs.reload.init) this.$refs.reload.init();
        },
        //修改密码
        revise_cipher() {
            if (!this.cipher) return this.alertErr('请认真填写密码！')
            var uname = this.$store.state.userInfo.uname
            var pwd = JSON.parse(JSON.stringify(this.cipher))
            pwd = md5(pwd).substr(2, 28)
            var _this = this
            this.requestApi('/adm/revise_cipher', {
                uname: uname,
                pwd: pwd
            }).then(function (res) {
                if (res.status == 200) {
                    _this.alertSucc(res.msg)
                    _this.modal = false
                } else {
                    _this.alertErr('修改失败')
                }
            })
        }
    }
}
</script>
<style>
@import "./assets/public/css/index.css";
@import "./assets/public/css/common.css";
@import "//at.alicdn.com/t/c/font_2601133_cr1mmynsoa4.css";
</style>
