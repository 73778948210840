export default [
    {
        path:'/iot/home/index',
        name:'IotHomeIndex',
        component:()=>import('../views/iot/home/index.vue'),
        meta:{
            title: 'IOT平台-首页',
            keepAlive: false
        }
    }
]
