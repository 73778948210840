export default [
    {
        path: '/aipayment',
        name: 'AiPayment',
        component:()=>import('../views/ai/aipayment'),
        meta:{
            title: '寻疗AI',
            keepAlive: false
        }
    },
    {
        path: '/aidtl',
        name: 'AiDtl',
        component:()=>import('../views/ai/aidtl'),
        meta:{
            title: 'AI充值',
            keepAlive: false
        }
    },
]
