<template>
	<div class="main">
		<div class="welcome">
			您好 <span style="color: #f75158;">{{ user.uname }}</span>,欢迎使用寻疗智慧健康管理后台！
			现在时间是：<span style="color: #f75158;">{{ datetime }}</span>
		</div>
		<div class="show-data">
			<div class="data-item">
				<div class="item-img"><i class="iconfont icon-zhandian"></i></div>
				<div class="text"><span class="label">站点数&nbsp;&nbsp;</span><span class="amount"
						style="color: #53e0f7">{{ sum.site }}</span>
				</div>
			</div>
			<div class="data-item">
				<div class="item-img" style="background-color: #edcc29;"><i class="iconfont icon-duanxin"></i></div>
				<div class="text"><span class="label">短信数&nbsp;&nbsp;</span><span class="amount"
						style="color: #edcc29;">{{ sum.message }}</span>
				</div>
			</div>
			<!-- <div class="data-item">
        <div class="item-img" style="background-color:#d81e06"><i class="iconfont icon-wuguan"></i></div>
        <div class="text"><span class="label">AI数&nbsp;&nbsp;</span><span class="amount"
            style="color:#d81e06">{{sum.ai}}</span>
        </div>
      </div> -->
			<div class="data-item">
				<div class="item-img" style="background-color: #00a2ff"><i class="iconfont icon-yingjian"></i></div>
				<div class="text"><span class="label">硬件数&nbsp;&nbsp;</span><span class="amount"
						style="color:#00a2ff">{{ sum.hardware }}</span>
				</div>
			</div>
		</div>
		<div class="my-site">
			<div class="title">我的站点</div>
			<div class="site-cont">
				<div class="site-item" v-for="(item,index) in siteItem" :key="index">
					<div v-if="item.is_sass" class="is_sass">{{ item.auth_site == 'adm.sz90.cn' ? 'sass' : '私有化' }}</div>
					<!-- <div class="current_version">{{ item.current_version }}</div> -->
					<div class="site-img"><img :src="item.icon.indexOf('http') != -1 ? item.icon : (item.icon.indexOf('/uploads') != -1 ? item.auth_site + item.icon : 'https://' + item.icon)" alt=""></div>
					<div class="site-text">
						<div>{{ item.name }}</div>
						<div>{{ item.auth_site }}</div>
					</div>
					<a style="color: #fff" target="_blank" :href="'https://'+item.auth_site">
						<div class="action">
							<p>管</p>
							<p>理</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	@import "../../assets/public/css/home.css";
</style>
<script>
	export default {
		name: 'Home',
		data() {
			return {
				user: JSON.parse(localStorage.getItem('userInfo')),
				datetime: null,
				siteItem: [],
				sum: {
					site: 0,
					message: 0,
					ai: 0,
					hardware: 0
				}
			}
		},

		mounted() {
			var _this = this;
			setInterval(function() {
				_this.datetime = _this.getDateTime()
			}, 1000)
		},
		created() {
			if (this.$store.state.userInfo.uid) {
				this.init()
			}
		},
		methods: {
			init() {
				let _this = this
				this.requestApi('/adm/get_index', {}).then(
					(res) => {
						_this.sum = res.data;
						if(res.siteItem){
							_this.siteItem = res.siteItem;
						}
					}
				)
			},
			getDateTime() {
				var date = new Date();
				var y = date.getFullYear(),
					m = date.getMonth() + 1,
					d = date.getDate(),
					h = this.sendDate(date.getHours()),
					i = this.sendDate(date.getMinutes()),
					s = this.sendDate(date.getSeconds());
				date = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
				return date;
			},
			sendDate(d) {
				if (parseInt(d) < 10) {
					d = '0' + d;
				}
				return d;
			}
		}
	}
</script>
