export default [
    {
        path:'/sms/package',
        name:'Package',
        component:()=>import('../views/sms/smspackage'),
        meta:{
            title:'短信套餐',
            keepAlive:true
        }
    },
    {
        path:'/sms/smsignList',
        name:'SmsignList',
        component:()=>import('../views/sms/smsignList'),
        meta:{
            title:'签名列表',
            keepAlive:true
        }
    },
    {
        path:'/sms/temp',
        name:'SmsTemp',
        component:()=>import('../views/sms/smstemp'),
        meta:{
            title:'短信模板',
            keepAlive:true
        }
    },
    {
        path:'/sms/log',
        name:'SmsLog',
        component:()=>import('../views/sms/smslog'),
        meta:{
            title:'发送记录',
            keepAlive:true
        }
    },
    {
        path:'/sms/actionlist',
        name:'ActionList',
        component:()=> import('../views/sms/actionlist'),
        meta:{
            title:'使用情况',
            keepAlive:true
        }
    },
	{
		path: '/sms/smsList',
		name: 'SmsList',
		component: () => import('../views/sms/smsList'),
		meta: {
			title: '使用情况',
			keepAlive: true
		}
	}
]
