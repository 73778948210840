import Vue from 'vue';
import axios from 'axios';
import router from './router'

Vue.use(router)

/* 后端api地址*/
Vue.prototype.ApiUrl = process.env.NODE_ENV == 'development' ? 'http://api.xlcloud.cc/index.php' :
	'https://api.cloud.sz90.cn/index.php';

// Vue.prototype.ApiUrl = 'https://api.cloud.sz90.cn/index.php'
// Vue.prototype.ApiUrl = 'https://cs.cloud.sz90.cn/index.php'
/* 后端api图片地址*/
Vue.prototype.ImgUrl = process.env.NODE_ENV == 'development' ? 'http://api.xlcloud.cc' : 'https://api.cloud.sz90.cn';
/*
  路由跳转 url 跳转路由地址 string 必须 query 额外参数 object 可选
 */
Vue.prototype.toUrl = function(url, query) {
	const queryParams = {
		...query
	}
	this.$router.push({
		path: url,
		query: queryParams
	});
}
/*
  url 请求地址 string 必须
  data 请求参数 object 可选
  headers 请求头 默认不需要传值
 */
Vue.prototype.requestApi = function(url, data, headers) {
	let header = Object.assign({
		'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
	}, headers);
	// axios.defaults.baseURL = '/admin';
	return new Promise((resolve) => {
		axios.interceptors.request.use(
			config => {
				const token = this.getToken();
				token && (config.headers.authorize = token);
				return config;
			}, error => {
				return Promise.error(error);
			});
		axios({
			method: typeof data == "object" ? 'POST' : 'GET',
			url: this.ApiUrl + url,
			data: data ? data : {},
			headers: header,
			responseType: 'json',
		}).then(res => {
			if (res.status == 200) {
				if (res.data.status == 403) {
					this.alertErr(res.data.msg);
				} else if (res.data.status == 401) {
					this.toUrl('/login')
					this.alertErr(res.data.msg);
				} else {
					resolve(res.data);
				}
			}
		}).catch(err => {
			this.alertErr('请求失败！', 4.5)
			console.log(err)
		})
	})
}
/* 获取token */
var delay_status = 0;
var out_time = 43200;
Vue.prototype.getToken = function() {
	let token = localStorage.getItem('store_token');
	if (token && !delay_status) {
		let store_out_time = localStorage.getItem('store_out_time'),
			now = new Date().getTime();
		now = parseInt(now.toString().substr(0, 10));
		if (!store_out_time || (store_out_time - now) < (out_time - 600)) {
			delay_status = 1;
			this.requestApi('/adm/delay').then(function(res) {
				if (res.status == 1) {
					localStorage.setItem('store_out_time', res.out_time);
					delay_status = 0;
				} else {
					delay_status = 0;
				}
			})
		}
	}
	return token;
}
Vue.prototype.alertSucc = function(txt, duration) {
	this.$Message.success({
		content: txt,
		background: true,
		duration: duration ? duration : 2
	})
}
/* 全局错误消息提醒 */
Vue.prototype.alertErr = function(txt, duration) {
	this.$Message.error({
		content: txt,
		background: true,
		duration: duration ? duration : 2
	})
}
Vue.prototype.getDateTime = function(date) {
	date = typeof date != "undefined" ? date : new Date();
	var y = date.getFullYear(),
		m = sendDate(date.getMonth() + 1),
		d = date.getDate(),
		h = sendDate(date.getHours()),
		i = sendDate(date.getMinutes()),
		s = sendDate(date.getSeconds());
	return date = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
}

function sendDate(d) {
	if (parseInt(d) < 10) {
		d = '0' + d;
	}
	return d;
}
/**
 * 浮点型计算转换（四舍五入）
 * @param expression 数值或表达式
 * @param digit 转换位数
 */
Vue.prototype.formatFloat = function(expression, digit) {
	var m = Math.pow(10, digit);
	return Math.round(expression * m) / m;
}